<template>
  <div class="dashboard">
    <div class="title-section">
      <h1 class="title">
        Studio Perf
      </h1>
    </div>
    <section class="content">
      <filters
        :allow-future="false"
        :allow-date-range="false"
        :variant-options="variantOptions"
      />
      <section>
        <grid
          :columns="columns"
          :default-hidden-columns="defaultHiddenColumns"
          :data="kpis"
          :loading="$apollo.loading"
          :auto-group-column="autoGroupColumnDef"
          :show-footer="false"
          :group-default-expanded="1"
          :side-bar="sidebar"
          @ready="api = $event"
        />
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';
import { GET_STUDIO_PERF } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';

export default {
  mixins: [GridMixin],
  data() {
    return {
      api: null,
      studioPerfData: [],
      rawKpis: {},
      aggregationKpis: {},
      variantOptions: {},
      sidebar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: true,
              suppressValues: true,
              contractColumnSelection: true,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: true,
            },
            minWidth: 180,
            maxWidth: 400,
            width: 250,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: 'App',
        field: 'appName',
        pinned: 'left',
        resizable: true,
        suppressMovable: true,
        cellRendererParams: {
          suppressCount: true,
        },
        valueGetter: (params) => {
          if (params.data?.studio === 'Total') {
            return params.data?.kpi;
          }
          const field = 'appName';
          const data = params.data?.[field];
          return data ?? undefined;
        },
      };
    },
    timePeriods() {
      const year = this.studioPerfData?.[0]?.currentYear;
      const month = this.studioPerfData?.[0]?.currentMonth;

      if (this.getGranularity === 'weekly') {
        const week = this.studioPerfData?.[0]?.currentWeek;
        const dates = Array.from(
          new Set(
            this.studioPerfData
              ?.flatMap((data) => data?.weeklyStudioData?.filter((period) => period.value !== null)?.map((period) => period.week) ?? [])
              ?? [],
          ),
        );

        const fieldFormat = 'YYYYMMDD';
        const labelFormat = 'DD/MM/YY';

        return dates
          .sort((a, b) => b - a)
          .map((date) => {
            const timePeriod = moment(new Date(week)).subtract(date, 'weeks');
            const startOfWeek = timePeriod.format(labelFormat);
            const endOfWeek = timePeriod.clone().add(6, 'days').format(labelFormat);
            const label = `${startOfWeek} - ${endOfWeek}`;

            return {
              field: timePeriod.format(fieldFormat),
              label,
            };
          });
      }

      const dates = Array.from(
        new Set(
          this.studioPerfData
            ?.flatMap((data) => data?.monthlyStudioData?.filter((period) => period.value !== null)?.map((period) => period.month) ?? [])
          ?? [],
        ),
      );

      const fieldFormat = 'YYYYMM';
      const labelFormat = 'MMMM YYYY';

      return dates
        .sort((a, b) => b - a)
        .map((date) => {
          const timePeriod = moment(new Date(year, month - 1)).subtract(date, 'months');
          return {
            field: timePeriod.format(fieldFormat),
            label: timePeriod.format(labelFormat),
          };
        });
    },
    defaultHiddenColumns() {
      const columns = [];

      if (this.getGranularity !== 'weekly') {
        this.timePeriods.forEach((period) => {
          columns.push(
            `month.${period.field}.target`,
            `month.${period.field}.targetDiff`,
            `month.${period.field}.targetDiffPercent`,
          );
        });
      }

      return columns;
    },
    columns() {
      const columns = [];

      columns.push({
        headerName: 'Studio',
        field: 'studio',
        colId: 'studio',
        filter: true,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
      });

      columns.push({
        headerName: 'KPI',
        colId: 'kpi',
        hide: true,
        rowGroup: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (params.data?.studio === 'Total') {
            return undefined;
          }
          const field = 'kpi';
          return params.data?.[field];
        },
      });

      this.timePeriods.forEach((period) => {
        const currentPeriodNumeric = period.field * 1;
        const headerClass = currentPeriodNumeric === this.lastPeriod ? 'current-month-header' : '';
        const hide = false;

        const actualColumn = {
          headerName: 'Actual',
          field: `${period.field}.actual`,
          colId: `month.${period.field}.actual`,
          type: 'numericColumn',
          aggFunc: this.customAggregation,
          resizable: true,
          width: 110,
          hide,
          headerClass,
          valueFormatter: (val) => {
            const kpi = val.data?.kpi ?? val.node?.key;
            return this.formatKpi(val.value, kpi);
          },
        };

        const targetColumn = {
          headerName: 'Target',
          field: `${period.field}.target`,
          colId: `month.${period.field}.target`,
          type: 'numericColumn',
          aggFunc: this.customAggregation,
          resizable: true,
          width: 110,
          hide,
          headerClass,
          valueFormatter: (val) => {
            const kpi = val.data?.kpi ?? val.node?.key;
            return this.formatKpi(val.value, kpi);
          },
        };

        const diffColumns = {
          headerName: 'Diff',
          headerClass,
          children: [
            {
              headerName: 'Value',
              field: `${period.field}.targetDiff`,
              colId: `month.${period.field}.targetDiff`,
              type: 'numericColumn',
              aggFunc: this.customAggregation,
              resizable: true,
              width: 110,
              hide,
              headerClass,
              valueFormatter: (val) => {
                const kpi = val.data?.kpi ?? val.node?.key;
                return this.formatKpi(val.value, kpi, true);
              },
            },
            {
              headerName: '%',
              field: `${period.field}.targetDiffPercent`,
              colId: `month.${period.field}.targetDiffPercent`,
              type: 'numericColumn',
              aggFunc: this.customAggregation,
              resizable: true,
              width: 110,
              hide,
              headerClass,
              cellStyle: (params) => this.cellStyle(params),
            },
          ],
        };

        const childrenColumns = this.getGranularity === 'weekly'
          ? [actualColumn]
          : [
            {
              headerName: 'Data',
              headerClass,
              children: [
                actualColumn,
                targetColumn,
              ],
            },
            diffColumns,
          ];

        columns.push({
          headerName: period.label,
          colId: currentPeriodNumeric,
          headerClass,
          children: childrenColumns,
        });
      });

      return columns;
    },
    kpis() {
      return Object.values(this.rawKpis).map((item) => ({ ...item }));
    },
    dataChange() {
      const { studioPerfData } = this;
      if (studioPerfData) {
        return {
          studioPerfData,
        };
      }
      return null;
    },
  },
  watch: {
    studioPerfData() {
      this.formatKpiAgGrid();
    },
    dataChange: {
      handler(val) {
        if (val) {
          this.formatKpiAgGrid();
        }
      },
      deep: true,
    },
  },
  methods: {
    customAggregation(params) {
      const { values, rowNode, column } = params;
      const kpi = rowNode?.key;
      const app = rowNode?.parent?.key;
      const field = column?.colDef?.field;

      if (rowNode.level > 0 && values.filter(Boolean).length > 0) {
        const [date, property] = field.split('.');
        return this.aggregationKpis?.[app]?.[kpi]?.[date]?.[property];
      }

      return undefined;
    },
    formatDate(year, month, input) {
      const timePeriod = moment(new Date(year, month - 1)).subtract(input, 'months');
      return timePeriod.format('YYYYMM');
    },
    formatWeekDate(currentWeek, subWeeks) {
      const date = new Date(currentWeek);
      date.setDate(date.getDate() - (subWeeks * 7));

      return `${date.getFullYear()}${this.withLeadingZero(date.getMonth() + 1)}${this.withLeadingZero(date.getDate())}`;
    },
    formatKpiAgGrid() {
      const rowData = {};
      const aggregationData = {};

      this.studioPerfData
        .forEach((entry) => {
          const {
            studio, appName, kpi,
            currentYear, currentMonth, currentWeek,
            monthlyStudioData, weeklyStudioData,
          } = entry;

          const key = `${studio}-${kpi}-${appName}`;

          if (appName !== 'Total') {
            if (!rowData[key]) {
              rowData[key] = {
                studio,
                kpi,
                appName: appName !== 'All' ? appName : undefined,
              };
            }

            if (monthlyStudioData) {
              monthlyStudioData
                .filter((monthly) => monthly.value)
                .forEach((monthly) => {
                  const monthDate = this.formatDate(currentYear, currentMonth, monthly.month);

                  rowData[key][monthDate] = {
                    actual: monthly.value,
                    target: monthly.target,
                    targetDiff: monthly.targetDiff,
                    targetDiffPercent: this.formatCompletion(monthly.targetDiffPercent),
                  };
                });
            } else {
              weeklyStudioData
                .filter((weekly) => weekly.value)
                .forEach((weekly) => {
                  const weekDate = this.formatWeekDate(currentWeek, weekly.week);

                  rowData[key][weekDate] = {
                    actual: weekly.value,
                  };
                });
            }
          } else {
            if (!aggregationData[studio]) {
              aggregationData[studio] = {};
            }

            if (!aggregationData[studio][kpi]) {
              aggregationData[studio][kpi] = {};
            }

            if (monthlyStudioData) {
              monthlyStudioData
                .filter((monthly) => monthly.value)
                .forEach((monthly) => {
                  const monthDate = this.formatDate(currentYear, currentMonth, monthly.month);

                  aggregationData[studio][kpi][monthDate] = {
                    actual: monthly.value,
                    target: monthly.target,
                    targetDiff: monthly.targetDiff,
                    targetDiffPercent: this.formatCompletion(monthly.targetDiffPercent),
                  };
                });
            } else {
              weeklyStudioData.forEach((weekly) => {
                const weekDate = this.formatWeekDate(currentWeek, weekly.week);
                aggregationData[studio][kpi][weekDate] = {
                  actual: weekly.value,
                };
              });
            }
          }
        });

      this.rawKpis = rowData;
      this.aggregationKpis = aggregationData;
    },
  },
  apollo: {
    studioPerfData: {
      query: GET_STUDIO_PERF,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        return {
          weekly: this.getGranularity === 'weekly',
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .content {
    flex: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .content-wrapper {
      flex: 1;
      display: flex;
      max-height: 100%;
    }
  }

  .week-picker ::v-deep .dropdown .datepicker-content .datepicker-body .datepicker-row:hover {
    background: #f2f2f2;

    & .datepicker-cell {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .week-picker .datepicker-content .datepicker-body.datepicker-row {
    background: green;
  }

  .weekly-date {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 10px;
    }
  }
}

.current-month-header {
  background-color: #f2effb;
  color: #552fbc;
}

.export-filters {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
