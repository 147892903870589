import gql from 'graphql-tag';

export const GET_APPS = gql`
  {
    apps {
      id
      apple_id
      revenuecat_id
      name
      sku
    }
  }
`;

export const GET_REVENUES = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String) {
    revenues(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appName
      studio
      country
      productName
      month
      year
      day
      paidRevenue
      iapRevenue
      adsRevenue
    }
  }
`;

export const GET_REVENUES_LEGACY = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantProduct: Boolean!, $isVariantCountry: Boolean!) {
    revenuesByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      appSku
      proceeds
      month
      year
      weekOfYear @include(if: $weekly)
      country @include(if: $isVariantCountry)
      productSku @include(if: $isVariantProduct)
      productName @include(if: $isVariantProduct)
      ads @skip(if: $isVariantProduct)
    }
  }
`;

export const GET_LTV = gql`
  query ($from: String!, $to: String!, $variant: String, $isWeekly: Boolean!) {
    ltvByApp(from: $from, to: $to, variant: $variant, weekly: $isWeekly) {
      appName
      month
      year
      country
      productName
      nbTrials
      ltv3yLow
      ltv3y
      ltv3yUp
      initialLtvLow
      initialLtv
      initialLtvUp
      momEvol
      cpa
      breakeven
    }
  }
`;

export const GET_MARGINS = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantCountry: Boolean!) {
    marginsByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      month
      year
      weekOfYear @include(if: $weekly)
      country @include(if: $isVariantCountry)
      ads
      proceeds
      spent
    }
  }
`;

export const GET_SPENT = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantCountry: Boolean!, $isVariantChannel: Boolean!) {
    spentByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      appSku
      amount
      month
      year
      weekOfYear @include(if: $weekly)
      country @include(if: $isVariantCountry)
      channelName @include(if: $isVariantChannel)
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query ($from: String!, $to: String!, $weekly: Boolean!) {
    dashboardData(from: $from, to: $to, weekly: $weekly) {
      appId
      appName
      country
      roas3y
      spent
      trials
      ltv3yeur
      revenue3yeur
      cpa
    }
  }
`;

export const GET_APPS_DASHBOARD_DATA = gql`
  query ($from: String!, $to: String!, $weekly: Boolean!) {
    appsDashboardData(from: $from, to: $to, weekly: $weekly) {
      appName
      country
      kpi
      currentYear
      currentMonth @skip(if: $weekly)
      monthlyByApps @skip(if: $weekly) {
        month
        value
        monthOverMonth
        monthOverMonthPercent
      }
      currentWeek @include(if: $weekly)
      weeklyByApps @include(if: $weekly) {
        week
        value
        weekOverWeek
        weekOverWeekPercent
      }
    }
  }
`;

export const GET_MONTH_TO_DATE = gql`
  query ($from: String, $to: String) {
    monthToDateData(from: $from, to: $to) {
      appName
      year
      month
      country
      kpi
      target
      targetMtd
      actual
      diff
      diffPercent
      mtdData {
        month
        actual
        mtd
        target
        targetMtd
        diffTarget
        diffPercentTarget
        diff
        diffPercent
      }
    }
  }
`;

export const GET_STUDIO_PERF = gql`
  query ($from: String, $to: String, $weekly: Boolean!) {
    studioPerfData(from: $from, to: $to, weekly: $weekly) {
      studio
      appName
      kpi
      currentYear
      currentMonth @skip(if: $weekly)
      monthlyStudioData @skip(if: $weekly) {
        month
        value
        target
        targetDiff
        targetDiffPercent
      }
      currentWeek @include(if: $weekly)
      weeklyStudioData @include(if: $weekly) {
        week
        value
      }
    }
  }
`;

export const GET_WEEKLY_PERF = gql`
  query ($from: String, $to: String) {
    weeklyPerfData(from: $from, to: $to) {
      appName
      country
      kpi
      weeklyData {
        week
        date
        value
        target
        targetDiff
        targetDiffPercent
        weekOverWeek
        weekOverWeekPercent
      }
    }
  }
`;
