<template>
  <div class="dashboard">
    <div class="title-section">
      <h1 class="title">
        Month to Date
      </h1>
      <div class="export-filters">
        <export :grid-api="api || undefined" />
      </div>
    </div>
    <section class="content">
      <filters
        :allow-future="false"
        :allow-granularity="false"
        :allow-date-range="false"
        :variant-options="variantOptions"
        :variant-options2="variantOptions2"
      />
      <section>
        <grid
          :columns="columns"
          :data="kpis"
          :loading="$apollo.loading"
          :auto-group-column="autoGroupColumnDef"
          :show-footer="false"
          :group-default-expanded="1"
          :side-bar="sidebar"
          @ready="api = $event"
        />
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';
import { GET_MONTH_TO_DATE } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/monthToDate/Export';

export default {
  components: { Export },
  mixins: [GridMixin],
  data() {
    return {
      api: null,
      monthToDateData: [],
      rawKpis: {},
      aggregationKpis: {},
      variantOptions: {
        title: 'Breakdown',
        list: [
          {
            key: 'country',
            name: 'KPI',
          },
          {
            key: 'kpi',
            name: 'Country',
          },
        ],
      },
      variantOptions2: {
        title: 'Month % Completion',
        key: 'month-completion',
        list: [
          {
            key: 'mtd',
            name: 'With ✔️',
          },
          {
            key: 'm',
            name: 'Without ✖️',
          },
        ],
      },
      sidebar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: true,
              suppressValues: true,
              contractColumnSelection: true,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: true,
            },
            minWidth: 180,
            maxWidth: 400,
            width: 250,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getVariant', 'getVariant2']),
    autoGroupColumnDef() {
      return {
        headerName: 'KPI',
        pinned: 'left',
        resizable: true,
        suppressMovable: true,
        cellRendererParams: {
          suppressCount: true,
        },
        valueGetter: (params) => {
          if (params.data?.appName === 'Total' && this.isVariantCountry) {
            return params.data?.kpi;
          }
          const field = this.isVariantCountry ? 'country' : 'kpi';
          return params.data?.[field];
        },
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantMTD() {
      return this.getVariant2['month-completion'] === 'mtd';
    },
    timePeriods() {
      const today = new Date(Date.now() - 24 * 3600 * 1000 * 2); // - 2 days
      const timePeriods = [];
      const timePeriod = moment(new Date(today.getFullYear(), today.getMonth() - 11, today.getDate()));
      const end = moment(new Date(today.getFullYear(), today.getMonth(), today.getDate())).endOf('month');
      const fieldFormat = 'YYYYMM';
      const labelFormat = 'MM/YY';
      while (timePeriod.isSameOrBefore(end)) {
        timePeriods.push({
          field: timePeriod.format(fieldFormat),
          label: timePeriod.format(labelFormat),
        });
        const period = 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    lastPeriod() {
      return Math.max(...this.timePeriods.map((period) => period.field));
    },
    columns() {
      const columns = [];

      columns.push({
        headerName: 'App',
        field: 'appName',
        colId: 'appName',
        filter: true,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
      });

      columns.push({
        headerName: this.isVariantCountry ? 'KPI' : 'Country',
        colId: this.isVariantCountry ? 'kpi' : 'country',
        hide: true,
        rowGroup: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (params.data?.appName === 'Total' && this.isVariantCountry) {
            return undefined;
          }
          const field = this.isVariantCountry ? 'kpi' : 'country';
          return params.data?.[field];
        },
      });

      let periodsCount = this.timePeriods.length;
      this.timePeriods.forEach((period) => {
        periodsCount -= 1;
        const currentPeriodNumeric = period.field * 1;
        if (currentPeriodNumeric === this.lastPeriod) {
          columns.push({
            headerName: period.label,
            headerClass: 'current-month-header',
            children: [
              {
                headerName: 'Data',
                headerClass: 'current-month-header',
                children: [
                  {
                    headerName: 'Actual',
                    field: `${period.field}.actual`,
                    colId: `${period.field}.actual`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    headerClass: 'current-month-header',
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi);
                    },
                  },
                  {
                    headerName: 'Target',
                    field: this.isVariantMTD ? `${period.field}.targetMtd` : `${period.field}.target`,
                    colId: `${period.field}.target`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    headerClass: 'current-month-header',
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi);
                    },
                  },
                ],
              },
              {
                headerName: 'Diff',
                headerClass: 'current-month-header',
                children: [
                  {
                    headerName: 'Value',
                    field: `${period.field}.diff`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    headerClass: 'current-month-header',
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi, true);
                    },
                  },
                  {
                    headerName: '%',
                    field: `${period.field}.diffPercent`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    headerClass: 'current-month-header',
                    cellStyle: (params) => this.cellStyle(params),
                  },
                ],
              },
            ],
          });
        } else {
          columns.push({
            headerName: period.label,
            resizable: true,
            width: 103,
            hide: periodsCount > 3,
            children: [
              {
                headerName: 'Data',
                children: [
                  {
                    headerName: 'Actual',
                    field: this.isVariantMTD ? `${period.field}.actual` : `${period.field}.realActual`,
                    colId: `${period.field}.actual`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    hide: periodsCount > 3,
                    resizable: true,
                    width: 110,
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi);
                    },
                  },
                  {
                    headerName: 'Target',
                    field: this.isVariantMTD ? `${period.field}.targetMtd` : `${period.field}.target`,
                    colId: `${period.field}.targetMtd`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    hide: periodsCount > 3,
                    resizable: true,
                    width: 110,
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi);
                    },
                  },
                ],
              },
              {
                headerName: 'Diff vs target',
                children: [
                  {
                    headerName: 'Value',
                    field: this.isVariantMTD ? `${period.field}.diffTarget` : `${period.field}.diff`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    hide: true,
                    resizable: true,
                    width: 110,
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi, true);
                    },
                  },
                  {
                    headerName: '%',
                    field: this.isVariantMTD ? `${period.field}.diffPercentTarget` : `${period.field}.diffPercent`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    hide: true,
                    resizable: true,
                    width: 110,
                    cellStyle: (params) => this.cellStyle(params),
                  },
                ],
              },
            ],
          });
        }
      });

      return columns;
    },
    kpis() {
      return Object
        .values(this.rawKpis)
        .map((item) => ({ ...item }));
    },
    dataChange() {
      const { monthToDateData } = this;
      if (monthToDateData) {
        return {
          monthToDateData,
        };
      }
      return null;
    },
  },
  watch: {
    monthToDateData() {
      this.formatKpiAgGrid();
    },
    dataChange: {
      handler(val) {
        if (val) {
          this.formatKpiAgGrid();
        }
      },
      deep: true,
    },
    isVariantMTD: {
      async handler() {
        await this.timeout(100);
        this.api.refreshCells({ force: true, suppressFlash: false });
      },
    },
  },
  methods: {
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    customAggregation(params) {
      const { values, rowNode, column } = params;
      const kpi = rowNode?.key;
      const app = rowNode?.parent?.key;
      const field = column?.colDef?.field;

      if (rowNode.level > 0 && values.filter(Boolean).length > 0) {
        const [month, property] = field.split('.');
        return this.aggregationKpis?.[app]?.[kpi]?.[month]?.[property];
      }

      return undefined;
    },
    formatDate(year, month, subMonths) {
      const date = new Date(year, month - subMonths - 1);

      return `${date.getFullYear()}${this.withLeadingZero(date.getMonth() + 1)}`;
    },
    formatKpiAgGrid() {
      const rowData = {};
      const aggregationData = {};

      this.monthToDateData.forEach((entry) => {
        const {
          appName, kpi, year, month, mtdData,
          target, targetMtd, actual,
          diffTarget, diffPercentTarget, diff, diffPercent,
        } = entry;

        const country = entry.country !== 'All'
          ? entry.country
          : null;
        const key = `${appName}-${kpi}-${country}`;
        const date = `${year}${this.withLeadingZero(month)}`;

        if (entry.country !== null) {
          if (!rowData[key]) {
            rowData[key] = {
              appName,
              kpi,
              country,
            };
          }

          if (!rowData[key][date]) {
            rowData[key][date] = {
              actual,
              target,
              targetMtd,
              diffTarget,
              diffPercentTarget: this.formatCompletion(diffPercentTarget),
              diff,
              diffPercent: this.formatCompletion(diffPercent),
            };

            mtdData
              .filter((monthly) => monthly.actual)
              .forEach((monthly) => {
                const monthDate = this.formatDate(year, month, monthly.month);

                rowData[key][monthDate] = {
                  actual: monthly.mtd,
                  realActual: monthly.actual,
                  diffTarget: monthly.diffTarget,
                  diffPercentTarget: this.formatCompletion(monthly.diffPercentTarget),
                  diff: monthly.diff,
                  diffPercent: this.formatCompletion(monthly.diffPercent),
                  target: monthly.target,
                  targetMtd: monthly.targetMtd,
                };
              });
          }
        } else {
          if (!aggregationData[appName]) {
            aggregationData[appName] = {};
          }

          if (!aggregationData[appName][kpi]) {
            aggregationData[appName][kpi] = {};
          }

          if (!aggregationData[appName][kpi][date]) {
            aggregationData[appName][kpi][date] = {
              actual,
              target,
              targetMtd,
              diffTarget,
              diffPercentTarget: this.formatCompletion(diffPercentTarget),
              diff,
              diffPercent: this.formatCompletion(diffPercent),
            };
          }

          mtdData
            .filter((monthly) => monthly.actual)
            .forEach((monthly) => {
              const monthDate = this.formatDate(year, month, monthly.month);

              aggregationData[appName][kpi][monthDate] = {
                actual: monthly.mtd,
                realActual: monthly.actual,
                diffTarget: monthly.diffTarget,
                diffPercentTarget: this.formatCompletion(monthly.diffPercentTarget),
                diff: monthly.diff,
                diffPercent: this.formatCompletion(monthly.diffPercent),
                target: monthly.target,
                targetMtd: monthly.targetMtd,
              };
            });
        }
      });

      this.rawKpis = rowData;
      this.aggregationKpis = aggregationData;
    },
  },
  apollo: {
    monthToDateData: {
      query: GET_MONTH_TO_DATE,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .content {
    flex: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .content-wrapper {
      flex: 1;
      display: flex;
      max-height: 100%;
    }
  }

  .week-picker ::v-deep .dropdown .datepicker-content .datepicker-body .datepicker-row:hover {
    background: #f2f2f2;

    & .datepicker-cell {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .week-picker .datepicker-content .datepicker-body.datepicker-row {
    background: green;
  }

  .weekly-date {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 10px;
    }
  }
}

.current-month-header {
  background-color: #f2effb;
  color: #552fbc;
}

.export-filters {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
